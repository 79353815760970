import { API_REQUEST_STATES } from "store/apiRequestStates/constants";
import { fetchPropertyContent } from "./propertyContent.slice";

export const selectPropertyContent =
  (propertyCode) =>
  ({ propertyContent }) =>
    propertyContent?.data[propertyCode];

export const selectPropertyContentByOrs =
  (propertyOrsCode) =>
  ({ propertyContent }) => {
    const propertyOwsCode = Object.keys(propertyContent.data)?.find((key) => {
      return propertyContent?.data[key]?.orsCode === propertyOrsCode;
    });
    return propertyContent?.data[propertyOwsCode];
  };

export const selectAllPropertyContent = ({ propertyContent }) =>
  propertyContent?.data;

export const selectAllPropertyContentErrors = ({ propertyContent }) =>
  propertyContent?.error;

export const selectPropertyUrlName =
  (propertyCode) =>
  ({ propertyContent }) =>
    propertyContent?.data[propertyCode]?.urlName;

export const selectPropertyShortName =
  (propertyCode) =>
  ({ propertyContent }) =>
    propertyContent?.data[propertyCode]?.shortName;

export const selectPropertyImage =
  (propertyCode) =>
  ({ propertyContent }) => {
    return propertyContent?.data[propertyCode]?.upcomingTrip?.desktopImage
      ?.croppedPath;
  };
export const selectPropertyCurrency =
  (hotelCode) =>
  ({ propertyContent }) => {
    return propertyContent?.data[hotelCode]?.currency || "";
  };
export const selectPropertyTimeZone =
  (hotelCode) =>
  ({ propertyContent }) => {
    return propertyContent?.data[hotelCode]?.timeZone || "";
  };

export const selectIsLoadingProperty = ({ apiRequestStates }) => {
  const apiRequestState = apiRequestStates[fetchPropertyContent.type];
  return (
    (apiRequestState && apiRequestState === API_REQUEST_STATES.REQUEST) || false
  );
};
