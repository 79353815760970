import { ofType } from "redux-observable";
import { withLatestFrom, switchMap, mergeMap } from "rxjs/operators";
import { defer, EMPTY, forkJoin } from "rxjs";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router";
import identity from "lodash/identity";
import mapQueryParams from "utils/mapQueryParams";

import {
  unauthenticatedBookingPath,
  unauthenticatedBookingItineraryPath,
  unauthenticatedBookingTransportationPath,
  unauthenticatedBookingWelcomeAmenitiesPath,
  unauthenticatedBookingEstimatedTotalPath,
  unauthenticatedBookingUpgradeYourRoomPath,
  findReservationsPath,
  findReservationsTransportationPath,
  findReservationsWelcomeAmenitiesPath,
  findReservationsEstimatedTotalPath,
  unauthenticatedBookingAncillaryPath,
} from "Authentication/authenticationRoutes";
import {
  profilePath,
  profileBookingPath,
  profileBookingItineraryPath,
  editProfilePath,
  profileEmailSubscriptionsPath,
  profileMembershipPath,
} from "Profile/profileRoutes";
import ajaxWithHealthCheck$ from "api/ajaxWithHealthCheck";
import { selectIsUserLoggedIn } from "store/profile";
import { getLocaleFromPathname } from "config/languages";
import unauthenticatedBookingPathActions from "./unauthenticatedBookingPathActions";
import unauthenticatedBookingItineraryPathActions from "./unauthenticatedBookingItineraryPathActions";
import getProfileActions, { profileOnlyActions } from "./profileActions";
import editProfileActions from "./editProfileActions";

const paths = [
  profilePath.path,
  profileBookingPath.path,
  editProfilePath.path,
  profileEmailSubscriptionsPath.path,
  profileBookingItineraryPath.path,

  profileMembershipPath.path,

  unauthenticatedBookingPath.path,
  unauthenticatedBookingItineraryPath.path,
  unauthenticatedBookingTransportationPath.path,
  unauthenticatedBookingWelcomeAmenitiesPath.path,
  unauthenticatedBookingEstimatedTotalPath.path,
  unauthenticatedBookingUpgradeYourRoomPath.path,
  unauthenticatedBookingAncillaryPath.path,

  findReservationsPath.path,
  findReservationsTransportationPath.path,
  findReservationsWelcomeAmenitiesPath.path,
  findReservationsEstimatedTotalPath.path,
];

const getMatchedPath = (pathname) =>
  paths
    .map((path) => matchPath(pathname, path))
    .find((matched) => matched?.isExact);

const actionsForPathsMap = {
  [unauthenticatedBookingItineraryPath.path]:
    unauthenticatedBookingItineraryPathActions,
  [unauthenticatedBookingPath.path]: unauthenticatedBookingPathActions,
  [findReservationsPath.path]: unauthenticatedBookingPathActions,
};

const routerEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {
            location: { pathname },
          },
        },
        state,
      ]) => {
        const locale = getLocaleFromPathname(pathname);
        const isSignedIn = selectIsUserLoggedIn(state);

        // eslint-disable-next-line no-unused-vars
        const { completeParams } = mapQueryParams(state.router.location);

        const inProgressParams =
          state?.bookings?.bookingInProgressSearchParams || {};

        const ppMode =
          completeParams?.ppMode || inProgressParams?.ppMode || false;
        const ppUrl = completeParams?.ppUrl || inProgressParams?.ppUrl || "";
        let searchParams = {};
        if (ppMode) {
          searchParams = { ppMode, ppUrl };
        }

        return ajaxWithHealthCheck$({
          locale,
          searchParams,
        }).pipe(
          switchMap(() => {
            const matchedPath = getMatchedPath(pathname);
            const profileActions = getProfileActions({
              matchedPath,
              locale,
            });

            const actionsForPath = paths
              .filter((path) => matchPath(pathname, path))
              .map((path) => actionsForPathsMap[path])
              .filter(Boolean);

            return defer(() => {
              switch (matchedPath?.path) {
                case profilePath.path:
                case profileMembershipPath.path:
                  return profileActions(profileOnlyActions);

                case editProfilePath.path:
                case profileEmailSubscriptionsPath.path:
                  return profileActions(editProfileActions);

                case unauthenticatedBookingPath.path:
                case unauthenticatedBookingItineraryPath.path:
                case unauthenticatedBookingTransportationPath.path:
                case unauthenticatedBookingWelcomeAmenitiesPath.path:
                case unauthenticatedBookingEstimatedTotalPath.path:
                case unauthenticatedBookingUpgradeYourRoomPath.path:
                case unauthenticatedBookingAncillaryPath.path:
                case findReservationsPath.path:
                case findReservationsTransportationPath.path:
                case findReservationsWelcomeAmenitiesPath.path:
                case findReservationsEstimatedTotalPath.path:
                  return forkJoin(
                    actionsForPath.map((fn) =>
                      fn({
                        locale,
                        hotelCode: matchedPath.params.hotelCode,
                        reservationId: matchedPath.params.reservationId,
                        ancillaryName: matchedPath.params.ancillary,
                        isSignedIn,
                        state,
                      })
                    )
                  ).pipe(
                    mergeMap(identity),
                    mergeMap(({ actions }) => actions)
                  );

                default:
                  return EMPTY;
              }
            });
          })
        );
      }
    )
  );

export default routerEpic;
