import React from "react";
import { format, parseISO } from "date-fns";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import find from "lodash/fp/find";

import { useTranslation } from "hooks";
import formatPrice from "utils/formatPrice";

function ShoppingCartItem({ name, quantity, price, removeItem, requestDate }) {
  const { t } = useTranslation();

  return (
    <div className="shopping-cart-item">
      <div className="shopping-cart-item__row">
        <div className="shopping-cart-item__col">
          <div className="ty-c3 shopping-cart-item__item-name">{name}</div>

          <div className="ty-c4 shopping-cart-item__quantity">
            {t("Quantity")}: {quantity}
          </div>
          {requestDate && (
            <div className="ty-c4 shopping-cart-item__requestDate">
              {requestDate}
            </div>
          )}
        </div>

        <div className="shopping-cart-item__col">
          <div className="ty-c3 shopping-cart-item__price">
            {formatPrice({
              value: price.amount * quantity,
              currency: price.currencyCode,
            })}
          </div>

          <div className="ty-c4 shopping-cart-item__taxes">
            {t("Taxes & fees may apply")}
          </div>
        </div>
      </div>

      {removeItem && (
        <div className="shopping-cart-item__actions">
          <button
            type="button"
            onClick={removeItem}
            className="shopping-cart-item__remove ty-c4"
          >
            {t("Remove")}
          </button>
        </div>
      )}
    </div>
  );
}

export default function ShoppingCartSummary({
  requestedAmenities = [],
  totalPrice,
  removeItem = () => {},
}) {
  const { t } = useTranslation();

  return (
    <div className="shopping-cart-summary">
      <h3 className="shopping-cart-summary__title ty-b1">{t("Your Order")}</h3>

      <div className="shopping-cart-summary__items">
        {requestedAmenities.length === 0 && (
          <div className="shopping-cart-summary__no-items ty-c4">
            {t("There are no items added to order.")}
          </div>
        )}

        {requestedAmenities.map(
          ({ code, name, numQuantity, price, details }) => {
            const requestDate = flow(
              find("Request_Date"),
              get("Request_Date")
            )(details?.fields);
            const formattedRequestDate = requestDate
              ? format(parseISO(requestDate), "MMM d")
              : null;
            return (
              <ShoppingCartItem
                key={code}
                name={name}
                quantity={numQuantity}
                price={price}
                removeItem={() => removeItem(code)}
                requestDate={formattedRequestDate}
              />
            );
          }
        )}
      </div>

      <div className="shopping-cart-summary__total">
        <span className="ty-b1 shopping-cart__total__text">
          {t("Est. Total")}*
        </span>
        <span className="ty-c3 shopping-cart-summary__total__price">
          {formatPrice(totalPrice)}
        </span>
      </div>
    </div>
  );
}
