import queryString from "query-string";

import { languagesRouteParam, getUrlLocale } from "config/languages";
import env from "config/env";

const { STATIC_SITE_URL } = env;

export const signIn = {
  path: `${languagesRouteParam}/sign-in`,
  to: ({ locale = "en", queryParams = {} } = {}) => {
    return [
      `/${getUrlLocale(locale)}sign-in`,
      queryString.stringify(queryParams),
    ]
      .filter(Boolean)
      .join("?");
  },
  getPageTitle: () => "Sign In",
  htmlBodyClassName: "view-sign-in_2018__landing-page",
};

export const arrivalTransportationMobile = {
  path: `${languagesRouteParam}/transportation`,
};

export const employeeTravel = {
  path: `${languagesRouteParam}/employee-travel`,
};

export const employeeSignIn = {
  path: `${languagesRouteParam}/employee-travel/signin`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}employee-travel/signin`,
  getPageTitle: () => "Sign In",
  htmlBodyClassName: "view-sign-in_2018__landing-page employee-flow",
};

export const register = {
  path: `${languagesRouteParam}/register`,
  to: ({ locale = "en", queryParams = {} } = {}) => {
    return [
      `/${getUrlLocale(locale)}register`,
      queryString.stringify(queryParams),
    ]
      .filter(Boolean)
      .join("?");
  },
  getPageTitle: () => "Sign Up",
  htmlBodyClassName: "view-register_2018__do-you-have-areservation-with-us",
};

export const employeeRegister = {
  path: `${languagesRouteParam}/employee-travel/register`,
  to: ({ locale = "en" } = {}) =>
    `/${getUrlLocale(locale)}employee-travel/register`,
  getPageTitle: () => "Sign Up",
  htmlBodyClassName:
    "view-register_2018__do-you-have-areservation-with-us employee-flow",
};

export const unauthenticatedBookingPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode?/:reservationId?`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return [`/${getUrlLocale(locale)}upcoming-trip`, hotelCode, reservationId]
      .filter(Boolean)
      .join("/");
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingItineraryPath = {
  from: `${languagesRouteParam}/itinerary`,
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/itinerary`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}upcoming-trip/${hotelCode}/${reservationId}/itinerary`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingTransportationPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/transportation`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}upcoming-trip/${hotelCode}/${reservationId}/transportation`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingWelcomeAmenitiesPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/welcome-amenities`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}upcoming-trip/${hotelCode}/${reservationId}/welcome-amenities`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingEstimatedTotalPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/estimated-total`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}upcoming-trip/${hotelCode}/${reservationId}/estimated-total`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingAncillaryPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/:ancillary(discover|dining|experiences|spa)`,
  to: ({ propertyUrlName, ancillaryName, locale = "en" }) => {
    return `${STATIC_SITE_URL}/content/fshr/pages/${locale}/properties/${propertyUrlName}/${ancillaryName}`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

export const unauthenticatedBookingUpgradeYourRoomPath = {
  path: `${languagesRouteParam}/upcoming-trip/:hotelCode/:reservationId/upgrade-your-room`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}find-reservations/${hotelCode}/${reservationId}/upgrade-your-room`;
  },
  getPageTitle: () => "Your Upcoming Trip",
};

// Find A Reservation (a version of unauth upcoming trip)
export const findReservationsPath = {
  path: `${languagesRouteParam}/find-reservations/:hotelCode?/:reservationId?`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return [
      `/${getUrlLocale(locale)}find-reservations`,
      hotelCode,
      reservationId,
    ]
      .filter(Boolean)
      .join("/");
  },
  getPageTitle: () => "Find A Reservation",
};

export const findReservationsTransportationPath = {
  path: `${languagesRouteParam}/find-reservations/:hotelCode/:reservationId/transportation`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}find-reservations/${hotelCode}/${reservationId}/transportation`;
  },
  getPageTitle: () => "Find A Reservation",
};

export const findReservationsWelcomeAmenitiesPath = {
  path: `${languagesRouteParam}/find-reservations/:hotelCode/:reservationId/welcome-amenities`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}find-reservations/${hotelCode}/${reservationId}/welcome-amenities`;
  },
  getPageTitle: () => "Find A Reservation",
};

export const findReservationsEstimatedTotalPath = {
  path: `${languagesRouteParam}/find-reservations/:hotelCode/:reservationId/estimated-total`,
  to: ({ hotelCode, reservationId, locale = "en" }) => {
    return `/${getUrlLocale(
      locale
    )}find-reservations/${hotelCode}/${reservationId}/estimated-total`;
  },
  getPageTitle: () => "Find A Reservation",
};
